import React from 'react';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid } from '@material-ui/core';

/** Material UI Icons */
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles({
    root: {},
    copyright: {
        textAlign: 'left',
        '@media (max-width: 600px)': {
            textAlign: 'center'
        }
    },
    social: {
        textAlign: 'center',
        '@media (max-width: 600px)': {
            textAlign: 'center'
        }
    },
    footer: {
        marginTop: '100px', 
        marginBottom: '50px',
        '@media (max-width: 600px)': {
            marginTop: '50px'
        }
    }
});

const Footer = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Container maxWidth="lg" className={classes.footer}>
                {/*<Grid spacing={3} container style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={5} xs={12} className={classes.copyright}>
                        <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Finpis Tecnologia LTDA</Typography>
                        <Typography variant="body2" style={{color: '#707072', marginTop: '4px'}}>Uma empresa do grupo <a rel="noreferrer" style={{textDecoration: 'none', color: '#00A8FF'}} target="_blank" href="https://appandwebstudio.com.br">App & Web</a></Typography>
                    </Grid>
                    <Grid item md={5} xs={12} className={classes.social}>
                        <a rel="noreferrer" target="_blank" href="https://www.instagram.com/finpis/">
                            <InstagramIcon style={{color: '#6D0095', fontSize: '30px'}} />
                        </a>
                        &nbsp;
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/finpisbr">
                            <FacebookIcon style={{color: '#6D0095', fontSize: '30px'}} />
                        </a>
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                </Grid>*/}
                <Grid spacing={3} container style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item md={12} xs={12} className={classes.social}>
                        <a rel="noreferrer" target="_blank" href="https://www.instagram.com/finpis/">
                            <InstagramIcon style={{color: '#6D0095', fontSize: '26px'}} />
                        </a>
                        &nbsp;
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/finpisbr">
                            <FacebookIcon style={{color: '#6D0095', fontSize: '26px'}} />
                        </a>
                    </Grid>
                </Grid>
            </Container>

        </div>
    );

}

export default Footer;