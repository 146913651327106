import axios from 'axios';

let url = "";

if (window.location.hostname === 'localhost') {
   url = 'http://localhost:8000';
} else {
   url = 'http://api.finpis.siteoficial.ws';
}

export default axios.create({
  baseURL: url
});