import React, { useEffect, useState } from 'react';
import Scroll from 'react-scroll';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Grid, Paper } from '@material-ui/core';

/** Material UI Icons */
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

/** Assets */
import people from '../assets/img/people.png';
import surf from '../assets/img/surf.png';
import meditating from '../assets/img/meditating.png';
import teacher from '../assets/img/teacher.png';

/** Components */
import Header from '../compoments/header';
import Footer from '../compoments/footer';

var Link = Scroll.Link;
var Element = Scroll.Element;

const useStyles = makeStyles({
    root: {},
    buttonPurple: {
        background: '#6D0095',
        color: '#FFF',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        textTransform: 'none',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '8px',
        width: '200px',
        height: '46px',
        '&:hover': {
            background: '#6D0095'
        },
        '@media (max-width: 600px)': {
            width:'80%'
        }
    },
    buttonBlue: {
        background: '#00A8FF',
        color: '#FFF',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        textTransform: 'none',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '8px',
        width: '200px',
        height: '46px',
        '&:hover': {
            background: '#008FD9'
        },
        '@media (max-width: 600px)': {
            width:'100%',
            marginTop: '20px'
        }
    },
    meditating: {
        width: '70%',
        '@media (max-width: 600px)': {
            width: '100%',
            display: 'none'
        }
    },
    section1: {
        marginTop: '50px', 
        marginBottom: '50px',
        '@media (max-width: 600px)': {
            marginTop: '50px'
        }
    },
    section3: {
        paddingRight: '0px', 
        marginRight: '0px', 
        paddingTop: '100px',
        '@media (max-width: 600px)': {
            paddingTop: '0px',
        }
    },
    surfDesktop: {
        right: '0px', 
        paddingTop: '100px',
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    surfMobile: {
        right: '0px', 
        paddingTop: '100px',
        '@media (min-width: 601px)': {
            display: 'none'
        }
    },
    section4: {
        paddingLeft: '0px', 
        marginLeft: '0px',
        '@media (max-width: 600px)': {
            paddingTop: '100px'
        }
    },
    paperCookie: {
        position: 'fixed', 
        bottom: '20px', 
        width: '80%', 
        marginLeft: '10%'
    },
    divCookie: {
        padding: '20px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        '@media (max-width: 767px)': {
            display: 'block'
        }
    },
    textCookie: {
        '@media (max-width: 767px)': {
            textAlign: 'center'
        }
    }
});

const Home = props => {

    const classes = useStyles();
    const [cookie, setCoookie] = useState(false)

    useEffect(() => {

        if(localStorage.getItem('cookie')){

            setCoookie(true)

        }

    }, [])

    const handleAcceptCookie = () => {

        localStorage.setItem('cookie', true)
        setCoookie(true)

    }

    return (
        <>
            <div className={classes.root}>

                <Header displayButtons={true} />
                
                <Container maxWidth="lg" className={classes.section1}>
                    <Grid spacing={3} container>
                        <Grid item md={1}></Grid>
                        <Grid item md={4} style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <Typography variant="h4">Praticar atividades físicas nunca foi tão fácil!</Typography>
                                <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Conectamos você à profisionais de esportes e bem-estar.</Typography>
                                {/*<br></br>
                                <Link activeClass="active" to="firstInsideContainer" spy={true} smooth={true} duration={600}>
                                    <Button className={classes.buttonPurple} color="inherit">sou um profissional</Button>
                                </Link>
                                <br></br>
                                <br></br>
                                <Link activeClass="active" to="secondInsideContainer" spy={true} smooth={true} duration={600}>
                                    <Button className={classes.buttonBlue} color="inherit">quero praticar</Button>
                                </Link>*/}
                            </div>
                        </Grid>
                        <Grid item md={7} style={{display: 'flex', justifyContent: 'center'}}>
                            <img alt="Finpis" src={meditating} className={classes.meditating} />
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth="xl" style={{paddingLeft: '0px', marginLeft: '0px'}}>
                    <Grid spacing={3} container>
                        <Grid item md={4} xs={12} style={{left: '0px'}}>
                            <img alt="Finpis" src={people} style={{width: '80%'}} />
                        </Grid>
                        <Grid item md={5} xs={12} style={{paddingLeft: '16px', marginLeft: '16px', display: 'flex', alignItems: 'center'}}>
                            <div>
                                <Typography variant="h4">Queremos ajudar as pessoas a ter uma vida mais saudável</Typography>
                                <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Treine em casa, ao ar livre  ou em qualquer outro  ambiente. Basta entrar na plataforma e escolher o profissional que desejar.</Typography>
                            </div>
                        </Grid>
                        <Grid item md={3}></Grid>
                    </Grid>
                </Container>

                <Element name="secondInsideContainer">
                    <Container maxWidth="xl" className={classes.section3}>
                        <Grid spacing={0} container>
                            <Grid item md={3}></Grid>
                            <Grid item md={4} className={classes.surfMobile}>
                                <img alt="Finpis" src={surf} style={{width: '100%'}} />
                            </Grid>
                            <Grid item md={5}>
                                <div>
                                    <Typography variant="h4">Tenha uma vida mais saudável</Typography>
                                    <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Deixe o sedentarismo de lado. Temos diversas opções para que você praticar com profissionais capacitados.</Typography>
                                    <br></br>
                                    <br></br>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <AccountBoxOutlinedIcon style={{color: '#707072', fontSize: '30px'}} />
                                        <Typography variant="body1" style={{color: '#707072', paddingLeft: '20px'}}>Escolha um profissional</Typography>
                                    </div>
                                    <br></br>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <AlarmOnOutlinedIcon style={{color: '#707072', fontSize: '30px'}} />
                                        <Typography variant="body1" style={{color: '#707072', paddingLeft: '20px'}}>Solitice a qualquer momento</Typography>
                                    </div>
                                    <br></br>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <StarBorderOutlinedIcon style={{color: '#707072', fontSize: '30px'}} />
                                        <Typography variant="body1" style={{color: '#707072', paddingLeft: '20px'}}>Avalie</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} className={classes.surfDesktop}>
                                <img alt="Finpis" src={surf} style={{width: '100%'}} />
                            </Grid>
                        </Grid>
                    </Container>
                </Element>

                <Element name="firstInsideContainer">
                    <Container maxWidth="xl" className={classes.section4}>
                        <Grid spacing={3} container>
                            <Grid item md={4} style={{left: '0px'}}>
                                <img alt="Finpis" src={teacher} style={{width: '80%'}} />
                            </Grid>
                            <Grid item md={5} xs={12} style={{paddingLeft: '16px', marginLeft: '16px', display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <Typography variant="h4">Seja um profissional Finpis</Typography>
                                    <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Se você acabou de sair da faculdade, ou se já tem experiência, iremos te ajudar a consolidar o seu trabalho e atingir todo o seu potencial, lhe conectando ao maior número de pessoas possíveis.</Typography>
                                    <br></br>
                                    <br></br>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <AlarmOnOutlinedIcon style={{color: '#707072', fontSize: '30px'}} />
                                        <Typography variant="body1" style={{color: '#707072', paddingLeft: '20px'}}>Trabalhe quando quiser</Typography>
                                    </div>
                                    <br></br>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <MonetizationOnOutlinedIcon style={{color: '#707072', fontSize: '30px'}} />
                                        <Typography variant="body1" style={{color: '#707072', paddingLeft: '20px'}}>Ganhe mais baseado em feedbacks</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={3}></Grid>
                        </Grid>
                    </Container>
                </Element>

                <Footer />

            </div>

            {!cookie && (
                <Paper className={classes.paperCookie}>
                    <div className={classes.divCookie}>
                        <Typography className={classes.textCookie}>Utilizamos cookies essenciais e, ao continuar navegando, você concorda com estas condições.</Typography>
                        <Button onClick={handleAcceptCookie} className={classes.buttonBlue} style={{ boxShadow: 'none' }}>Entendi</Button>
                    </div>
                </Paper>
            )}
        </>
    );

}

export default Home;