import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Start from './pages/start';
import Home from '../src/pages/home.js';
import Login from '../src/pages/login.js';
import Register from '../src/pages/register.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="*" exact={true} component={Start} />
      {/* <Route path="/" exact={true} component={Home} />
      <Route path="/login" exact={true} component={Login} />
      <Route path="/login-professional" exact={true} component={Login} />
      <Route path="/register" exact={true} component={Register} />
      <Route path="/register-professional" exact={true} component={Register} /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();