import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import InputMask from 'react-input-mask';
import { DropzoneArea } from 'material-ui-dropzone';
import api from '../utils/api';
import qs from 'qs';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid, TextField, Button, Modal, Fade, Backdrop, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

/** Components */
import Header from '../compoments/header';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const professions = [
    { title: 'Personal trainer' },
    { title: 'Instrutor Ioga' }
];

const useStyles = makeStyles((theme) => ({
    root: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '15px',
        boxShadow: theme.shadows[5],
        width: '50%',
        height: 'auto',
        maxHeight: '60vh',
        overflowY: 'auto',
        padding: theme.spacing(2, 4, 3),
        ['@media (max-width: 779px)']: { // eslint-disable-line no-useless-computed-key
            width: '70%'
        }
    },
    dropzoneArea: {
        minHeight: 'auto',
        paddingBottom: '24px'
    },
    containerForm: {
        width: '50%',
        ['@media (max-width: 767px)']: {
            width: '90%'
        }
    },
    ButtonRegisterDisabled: {
        width: '100%', 
        background: '#C4C4C4', 
        color: '#FFF', 
        textTransform: 'none', 
        height: '60px', 
        fontSize: '16px', 
        borderRadius: '8px'
    },
    ButtonRegister: {
        width: '100%', 
        background: '#00A8FF', 
        color: '#FFF', 
        textTransform: 'none', 
        height: '60px', 
        fontSize: '16px', 
        borderRadius: '8px'
    }
}));

const Register = props => {

    const classes = useStyles();
    const location = useLocation();
    const page = location.pathname;
    const [registerModal, setRegisterModal] = useState(false);

    const [zipcode, setZipcode] = useState();
    const [address, setAddress] = useState();
    const [neighborhood, setNeighborhood] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {}, [])

    const handleCep = () => {

        axios.get('https://viacep.com.br/ws/' + zipcode + '/json/',{
            params: {},
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setAddress(res.data.logradouro);
            setNeighborhood(res.data.bairro);
            setCity(res.data.localidade);
            setState(res.data.uf);
        })
        .catch(res => {})

    }

    const [loading, setLoading] = useState(false)

    const handleRegisterProfessional = () => {

        setMessageSnackbar('');
        setSeveritySnackbar('error');
        setOpenSnackbar(false);

        setLoading(true);

        api.post('/auth/register/professional', qs.stringify({
            name: name,
            email: email,
            phone: phone
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept' : '*/*'
            }
        })
        .then(res => {
            setLoading(false);
            setName('');
            setEmail('');
            setPhone('');
            setRegisterModal(true);
        })
        .catch(err => {
            setLoading(false);
            setMessageSnackbar('Erro ao registrar. Tente novamente!');
            setSeveritySnackbar('error');
            setOpenSnackbar(true);
        })

    }

    const handleRegisterUser = () => {

        setMessageSnackbar('');
        setSeveritySnackbar('error');
        setOpenSnackbar(false);

        setLoading(true);

        api.post('/auth/register/user', qs.stringify({
            name: name,
            email: email,
            phone: phone
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept' : '*/*'
            }
        })
        .then(res => {
            setLoading(false);
            setName('');
            setEmail('');
            setPhone('');
            setRegisterModal(true);
        })
        .catch(err => {
            setLoading(false);
            setMessageSnackbar('Erro ao registrar. Tente novamente!');
            setSeveritySnackbar('error');
            setOpenSnackbar(true);
        })
        
    }

    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severitySnackbar, setSeveritySnackbar] = useState('error');

    return (
        <div className={classes.root}>

            <Header displayButtons={false} />

            <Container maxWidth="xl" style={{paddingTop: '40px', paddingBottom: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh'}}>

                <div className={classes.containerForm}>
                    {page === "/register-professional" ?
                        <>

                            <Typography variant="h4">Cadastre-se como profissional</Typography>
                            <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Insira seus dados</Typography>

                            <br></br>

                            <Grid spacing={3} container>
                                <Grid item md={12} xs={12}>
                                    <TextField value={name} onChange={(e) => {setName(e.target.value)}} InputProps={{ style: { borderRadius: '8px' } }} label="Nome completo" variant="outlined" type="text" style={{width: '100%'}} />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        onChange={(e) => {setPhone(e.target.value)}}
                                        value={phone}
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField InputProps={{ style: { borderRadius: '8px' } }} label="Celular" variant="outlined" type="tel" style={{width: '100%'}} />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} InputProps={{ style: { borderRadius: '8px' } }} label="E-mail" variant="outlined" type="text" style={{width: '100%'}} />
                                </Grid>
                            </Grid>

                            <br></br>
                            <Button disabled={!name || !email || !phone} onClick={handleRegisterProfessional} className={!name || !email || !phone ? classes.ButtonRegisterDisabled : classes.ButtonRegister}>
                                {loading ?
                                    <CircularProgress size={20} style={{color: '#FFF'}} />
                                :
                                    "cadastrar"
                                }
                            </Button>

                        </>
                    :
                        <>

                            <Typography variant="h4">Cadastre-se como usuário</Typography>
                            <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Insira seus dados</Typography>

                            <br></br>

                            <Grid spacing={3} container>
                                <Grid item md={12} xs={12}>
                                    <TextField value={name} onChange={(e) => {setName(e.target.value)}} InputProps={{ style: { borderRadius: '8px' } }} label="Nome completo" variant="outlined" type="text" style={{width: '100%'}} />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        onChange={(e) => {setPhone(e.target.value)}}
                                        value={phone}
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField InputProps={{ style: { borderRadius: '8px' } }} label="Celular" variant="outlined" type="tel" style={{width: '100%'}} />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} InputProps={{ style: { borderRadius: '8px' } }} label="E-mail" variant="outlined" type="text" style={{width: '100%'}} />
                                </Grid>
                            </Grid>

                            <br></br>

                            <Button disabled={!name || !email || !phone} onClick={handleRegisterUser} className={!name || !email || !phone ? classes.ButtonRegisterDisabled : classes.ButtonRegister}>
                                {loading ?
                                    <CircularProgress size={20} style={{color: '#FFF'}} />
                                :
                                    "cadastrar"
                                }
                            </Button>

                        </>
                    }
                </div>

            </Container>

            <Modal
                className={classes.modal}
                open={registerModal}
                onClose={() => {setRegisterModal(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={registerModal} style={{outline: 'none'}}>
                    <div className={classes.paper}>
                        <Typography variant="h5" style={{color: '#008000'}}>Cadastro realizado com sucesso!</Typography>
                        <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Enviamos um e-mail para que você possa validar sua conta.</Typography>
                        {/*<Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Enviamos um e-mail para que você possa validar sua conta. </Typography>
                        <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Estamos quase prontos para liberar a plataforma por completo. </Typography>
                        <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Até lá, acesse sua conta e deixe seu cadastro o mais completo possível. </Typography>
                        <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>Iremos avisá-lo sobre o início do uso da plataforma.</Typography>*/}
                        {/*<br></br>
                        <a href="/" style={{textDecoration: 'none'}}>
                            <Button onClick={() => {setRegisterModal(true)}} style={{width: '20%', background: '#00A8FF', color: '#FFF', textTransform: 'none', fontSize: '16px', borderRadius: '8px'}}>entendi</Button>
                        </a>*/}
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

        </div>
    );

}

export default Register;