import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Container, Modal, Backdrop, Fade, Paper, Grid } from '@material-ui/core';

/** Material UI Icons */
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

/** Assets */
import logo from '../assets/img/logo.png';

const useStyles = makeStyles(theme => ({
    root: {},
    title: {
        flexGrow: 1
    },
    logo: {
        width: '100px'
    },
    appBar: {
        backgroundColor: '#FFF',
        boxShadow: 'none',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    button: {
        background: '#FFF',
        color: '#00A8FF',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        textTransform: 'none',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '8px',
        height: '46px',
        '@media (max-width: 600px)': {
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    buttonActive: {
        background: '#FFF',
        color: '#00A8FF',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        textTransform: 'none',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '8px',
        height: '46px',
        border: '1px solid #00A8FF',
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    toolbar: {
        paddingLeft: '0px',
        paddingRight: '0px'
    }, 
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 4, 3),
        borderRadius: '0px',
        border: 'none',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    paperElement: {
        padding: '20px', 
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', 
        borderRadius: '15px',
        minHeight: '140px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const Header = props => {

    const classes = useStyles();

    const [registerModal, setRegisterModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);

    return (
        <div className={classes.root}>

            <AppBar position="static" className={classes.appBar}>
                <Container maxWidth="lg">
                    <Toolbar className={classes.toolbar}>
                        <Link to="/">
                            <img alt="Finpis" src={logo} className={classes.logo} />
                        </Link>
                        <Typography variant="h6" className={classes.title}></Typography>
                        {props.displayButtons && (
                            <>
                                <Button onClick={() => {setRegisterModal(true)}} className={!registerModal ? classes.button : classes.buttonActive} color="inherit">Cadastrar</Button>
                                <Button onClick={() => {setLoginModal(true)}} className={!loginModal ? classes.button : classes.buttonActive} color="inherit" style={{marginLeft: '10px'}}>Login</Button>
                            </>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={loginModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                onClose={() => {setLoginModal(false)}}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={loginModal} style={{outline: 'none'}}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setLoginModal(false)}} style={{top: '50px', right: '30px', cursor: 'pointer', fontSize: '30px', color: '#707072', position: 'absolute'}} />

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <div style={{width: '90%'}}>

                                <Grid container spacing={3}>
                                    <Grid item md={6}>
                                        <Link to="/login-professional" style={{textDecoration: 'none', color: 'black'}}>
                                            <Paper className={classes.paperElement}>
                                                <Typography style={{fontSize: '24px'}}>Faça login como profissional</Typography>
                                            {/*<Typography style={{fontSize: '18px', color: '#707072'}}>ou <a href="/register-professional" style={{textDecoration: 'none', color: '#6D0095', cursor: 'pointer'}}>cadastre-se</a> como profissional</Typography>*/}
                                            </Paper>
                                        </Link>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Link to="/login" style={{textDecoration: 'none', color: 'black'}}>
                                            <Paper className={classes.paperElement}>
                                                <Typography style={{fontSize: '24px'}}>Faça login como usuário</Typography>
                                                {/*<Typography style={{fontSize: '18px', color: '#707072'}}>ou <a href="/register" style={{textDecoration: 'none', color: '#6D0095', cursor: 'pointer'}}>cadastre-se</a> como usuário</Typography>*/}
                                            </Paper>
                                        </Link>
                                    </Grid>
                                </Grid>

                            </div>
                        </div>

                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={registerModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                onClose={() => {setRegisterModal(false)}}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={registerModal} style={{outline: 'none'}}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setRegisterModal(false)}} style={{top: '50px', right: '30px', cursor: 'pointer', fontSize: '30px', color: '#707072', position: 'absolute'}} />

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            <div style={{width: '90%'}}>

                                <Grid container spacing={3}>
                                    <Grid item md={6}>
                                        <Link to="/register-professional" style={{textDecoration: 'none', color: 'black'}}>
                                            <Paper className={classes.paperElement}>
                                                <Typography style={{fontSize: '24px'}}>Cadastre-se como profissional</Typography>
                                                {/*<Typography style={{fontSize: '18px', color: '#707072'}}>ou <a href="/login-professional" style={{textDecoration: 'none', color: '#6D0095', cursor: 'pointer'}}>faça login</a> como profissional</Typography>*/}
                                            </Paper>
                                        </Link>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Link to="/register" style={{textDecoration: 'none', color: 'black'}}>
                                            <Paper className={classes.paperElement}>
                                                <Typography style={{fontSize: '24px'}}>Cadastre-se como usuário</Typography>
                                                {/*<Typography style={{fontSize: '18px', color: '#707072'}}>ou <a href="/login" style={{textDecoration: 'none', color: '#6D0095', cursor: 'pointer'}}>faça login</a> como usuário</Typography>*/}
                                            </Paper>
                                        </Link>
                                    </Grid>
                                </Grid>

                            </div>
                        </div>

                    </div>
                </Fade>
            </Modal>

        </div>
    );

}

export default Header;