import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import qs from 'qs';
import api from '../utils/api';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Grid, TextField, CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

/** Material UI Icons */

/** Components */
import Header from '../compoments/header';

import axios from 'axios';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    root: {},
    containerForm: {
        width: '50%',
        ['@media (max-width: 767px)']: {
            width: '90%'
        }
    },
    ButtonRegisterDisabled: {
        width: '100%', 
        background: '#C4C4C4', 
        color: '#FFF', 
        textTransform: 'none', 
        height: '60px', 
        fontSize: '16px', 
        borderRadius: '8px'
    },
    ButtonRegister: {
        width: '100%', 
        background: '#00A8FF', 
        color: '#FFF', 
        textTransform: 'none', 
        height: '60px', 
        fontSize: '16px', 
        borderRadius: '8px'
    }
});

const Login = props => {

    const classes = useStyles();
    const location = useLocation();
    const page = location.pathname;

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [loading, setLoading] = useState(false)

    const handleLogin = () => {

        setMessageSnackbar('');
        setSeveritySnackbar('error');
        setOpenSnackbar(false);

        setLoading(true);

        api.post('/auth/login', qs.stringify({
            email: email,
            password: password
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept' : '*/*'
            }
        })
        .then(res => {
            setLoading(false);
            setMessageSnackbar('Redirecionando ...');
            setSeveritySnackbar('success');
            setOpenSnackbar(true);
            if(window.location.hostname === "localhost"){
                window.location.href = "http://localhost:3001?token="+res.data.token;
            }else{
                window.location.href = "http://app.finpis.siteoficial.ws?token="+res.data.token;
            }
        })
        .catch(err => {
            setLoading(false);
            setLoading(false);
            setMessageSnackbar('Dados incorretos');
            setSeveritySnackbar('error');
            setOpenSnackbar(true);
        })
        
    }

    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severitySnackbar, setSeveritySnackbar] = useState('error');

    return (
        <div className={classes.root}>

            <Header displayButtons={false} />

            <Container maxWidth="xl" style={{paddingTop: '40px', paddingBottom: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh'}}>
                
                <div className={classes.containerForm}>
                    <Typography variant="h4">Acesse sua conta</Typography>
                    <Typography variant="body1" style={{color: '#707072', marginTop: '10px'}}>
                        {page === "/login-professional" ? 
                            'Como um profissional'
                        :
                            'Como usuário'
                        }
                    </Typography>
                    <br></br>
                    <br></br>
                    <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} InputProps={{ style: { borderRadius: '8px' } }} label="E-mail" variant="outlined" type="text" style={{width: '100%'}} />
                    <br></br>
                    <br></br>
                    <TextField value={password} onChange={(e) => {setPassword(e.target.value)}} InputProps={{ style: { borderRadius: '8px' } }} label="Senha" variant="outlined" type="password" style={{width: '100%'}} />
                    <br></br>
                    <br></br>
                    <Button disabled={!email || !password} onClick={handleLogin} className={!email || !password ? classes.ButtonRegisterDisabled : classes.ButtonRegister}>
                        {loading ?
                            <CircularProgress size={20} style={{color: '#FFF'}} />
                        :
                            "entrar"
                        }
                    </Button>
                </div>
                    
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

        </div>
    );

}

export default Login;