import React from "react";
import { Container, Grid, Typography, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

/** Assets */
import logo from '../assets/img/logo.png';

const useStyles = makeStyles({
    img: {
        width: '100px',
        marginBottom: '20px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh'
    },
    subtitle: {
        color: '#707072', 
        fontWeight: 'lighter'
    },
    chip: {
        backgroundColor: '#6D0096',
        color: '#FFF',
        marginTop: '10px',
        borderRadius: '10px'
    }
});

const Start = props => {

    const classes = useStyles();

    return (
        <>
            
            <Container maxWidth="lg" className={classes.container}>
                <Grid spacing={3} container>
                    <Grid item md={12}>
                        <img alt="Finpis" src={logo} className={classes.img} />
                        <Typography variant="h4">Praticar atividades físicas nunca foi tão fácil!</Typography>
                        <Typography variant="h6" className={classes.subtitle}>Conectamos você à profisionais de esportes e bem-estar.</Typography>
                        <Chip className={classes.chip} label="Em breve" />
                    </Grid>
                </Grid>
            </Container>

        </>
    );

}

export default Start;